import { Dispatch, FC, useCallback, useEffect, useRef, useState } from "react";
import { scanImageData } from "zbar.wasm";
import './VideoElement.css';

interface Props {
    setOpen: Dispatch<boolean>;
    results: {result: string, type: string}[];
    setResults: Dispatch<{result: string, type: string}[]>;
}

export const VideoElement:FC<Props> = ({setOpen, results, setResults}) => {

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasContext = useRef<CanvasRenderingContext2D | null>(null);
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
    
    const [stream, setStream] = useState<MediaStream | null>(null);

    const doScan = async () => {
        console.log("doing scan");

        // Draw image to canvas and get image
        canvasRef.current!.width = videoRef.current!.videoWidth;
        canvasRef.current!.height = videoRef.current!.videoHeight;
        canvasContext.current = canvasRef.current!.getContext('2d')!;
        canvasContext.current!.drawImage(videoRef.current!, 0, 0, canvasRef.current!.width, canvasRef.current!.height);
        let imageData:ImageData = canvasContext.current!.getImageData(0, 0, canvasRef.current!.width, canvasRef.current!.height);
        
        // Scan the data
        const res = await scanImageData(imageData);
        console.log("scan analysed");
        if (res.length > 0) {
            console.log("scan succesfull");
            let locResults: {result: string, type: string}[] = results.map((x) => structuredClone(x));
            
            locResults.push({
                result: res[0].decode(),
                type: res[0].typeName
            });
            
            setResults(locResults);
            setOpen(false);
        }
        else {
            console.log("scan unsuccesfull - retrying");
            timeoutId.current = setTimeout(() => doScan(), 300);
        }
    };

    // Initiate scanning and start the scanning loop
    useEffect(() => {
        if (canvasRef.current !== null && !!videoRef.current?.videoWidth) {
            console.log("initiating video drawing");
            doScan();
        }
    }, [videoRef.current?.videoWidth]);

    // Attach stream to video
    // Initiate scan
    /*useEffect(() => {
        console.log("initiating scan");
        if (stream !== null && !!videoRef.current) {
            videoRef.current!.srcObject = stream;
        }
    }, [stream, videoRef.current]);*/

    // Initiate stream
    useEffect(() => {
        if (videoRef.current !== null) {
            console.log("initiating stream");
            navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                width: {ideal: 1280, max: 1920 },
                height: {ideal: 720, max: 1080 },
                facingMode: "environment"
            }
            }).then((stream) => {
                videoRef.current?.addEventListener("play", doScan);
                videoRef.current!.srcObject = stream;
            });
        }
    },  [videoRef.current]);

    // Build up / tear down
    useEffect(() => {
        return () => {
            setStream(null);
            videoRef.current?.removeEventListener("play", doScan);
            if (timeoutId.current !== null) {
                clearTimeout(timeoutId.current);
            }
        }
    }, []);

    return (
        <>
            <video playsInline={true} autoPlay={true} ref={videoRef} />
            <canvas ref={canvasRef}></canvas>
        </>
    );

};