import { useState } from 'react';
import './App.css';

import { VideoElement } from './components/VideoElement/VideoElement';

function App() {
  const [scannerOpen, setScannerOpen] = useState<boolean>(false);
  const [results, setResults] = useState<{result: string, type: string}[]>([]);

  return (
    <div className="App">
      <>
        {scannerOpen ? (
          <VideoElement setOpen={setScannerOpen} results={results} setResults={setResults} />
        ) : (
          <div className="scanBtn" onClick={() => setScannerOpen(true)}>Scan</div>
        )}

        <div className="results">
          {results.map((item, index) => (
            <p key={"result" + index}>Scan: <strong>{item.result}</strong> ({item.type})</p>
          ))}
        </div>
      </>
    </div>
  );
}

export default App;
